export default {
  focusOutline: 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-purple-600 focus:ring-opacity-100',
  focusOutlineNoOffset: 'focus:outline-none focus:ring focus:ring-purple-600 focus:ring-opacity-100',
  form: {
    label: 'block font-bold text-sm text-gray-700'
  },
  card: {
    base: 'bg-white border border-nl-light-color sm:rounded-3xl box-shadow'
  }
};
