<template>
    <div>
        <button :class="classes" class="w-full text-left" v-if="as === 'button'">
            <slot></slot>
        </button>

        <inertia-link :href="href" :class="classes" v-else>
            <slot></slot>
        </inertia-link>
    </div>
</template>

<script>
import ComponentStyles from '@/Mixins/ComponentStyles';
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['active', 'href', 'as'],

  computed: {
    classes () {
      const focus = ComponentStyles.focusOutlineNoOffset;
      const base = `
        block pl-3 pr-4 py-2
        text-base font-medium
        transition duration-150 ease-in-out
        ${focus}
      `;
      return this.active
        ? base + 'text-black bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700'
        : `${base} text-white
          hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300
          focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 focus:outline-none`;
    }
  }
});
</script>
