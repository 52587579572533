<template>
    <a :href="href" :class="classes" v-if="isExternal" target="_blank">
        <slot></slot>
    </a>
    <inertia-link :href="href" :class="classes" v-else>
        <slot></slot>
    </inertia-link>
</template>

<script>
import ComponentStyles from '@/Mixins/ComponentStyles';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    active: Boolean,
    href: String,
    isExternal: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      const focus = ComponentStyles.focusOutlineNoOffset;
      const base = `
        inline-flex items-center
        px-1 pt-1
        border-b-4 text-lg
        font-medium leading-5 text-white
        transition duration-150 ease-in-out
        ${focus}
      `;
      return this.active
        ? `${base} border-white`
        : `${base} border-transparent hover:border-white`;
    }
  }
});
</script>
