<template>
    <div>
        <button type="submit" :class="classes" v-if="as === 'button'">
            <slot></slot>
        </button>

        <inertia-link :href="href" :class="classes" v-else>
            <slot></slot>
        </inertia-link>
    </div>
</template>

<script>
import ComponentStyles from '@/Mixins/ComponentStyles';
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['href', 'as'],

  computed: {
    classes () {
      const focus = ComponentStyles.focusOutlineNoOffset;
      return `
        block px-4 py-2 w-full
        text-sm leading-5 text-gray-700 text-left
        hover:bg-gray-100
        focus:outline-none focus:bg-gray-100
        transition duration-150 ease-in-out
        ${focus}
      `;
    }
  }
});
</script>
