<template>
    <div class="my-4 font-semibold text-xl text-nl-black leading-tight" v-if="visible">
        <span v-for="(page,i) in history" :key="i" :class="historyClasses">
            <inertia-link :href="page.href">
                {{ page.name }}
            </inertia-link> &gt;
        </span>
        <slot>{{ current_page }}</slot>
    </div>
</template>

<script>
import ComponentStyles from '@/Mixins/ComponentStyles';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    previous: {
      type: Array,
      default: null
    }
  },

  computed: {
    breadcrumb () {
      return this.$page.props.breadcrumb;
    },
    current_page () {
      return this.breadcrumb.current ?? '';
    },
    history () {
      return this.previous ?? this.breadcrumb.history ?? [];
    },
    historyClasses () {
      const focus = ComponentStyles.focusOutlineNoOffset;
      return `text-gray-600 text-sm ${focus}`;
    },
    visible () {
      return (this.current_page !== '' || this.history.length > 0);
    }
  }
});
</script>
