import _ from 'lodash';
import axios from 'axios';

window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;
//
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     forceTLS: true,
// });

// Extend string prototype
String.prototype.capitalise = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.titleise = function () {
  return this.trim().replace('_', ' ');
};

// Set up for Page Visibility API
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  window.hidden = 'hidden';
  window.visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  window.hidden = 'msHidden';
  window.visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  window.hidden = 'webkitHidden';
  window.visibilityChange = 'webkitvisibilitychange';
}
