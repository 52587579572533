import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
function addEcho () {
  window.Pusher = Pusher;

  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: import.meta.env.VITE_PUSHER_APP_KEY,
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      forceTLS: true
    });
  }
}

function removeEcho () {
  if (window.Echo) {
    window.Echo.disconnect();
    window.Echo = null;
  }
}

export {
  addEcho,
  removeEcho
};
