import { Breadcrumb } from '@/Components/Navigation';

import { createApp, h } from 'vue';
import { createInertiaApp, Head, Link, router } from '@inertiajs/vue3';
import Portal from 'vue3-portal';
import route from 'ziggy-js';
import { addEcho, removeEcho } from './echo';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from '@sentry/vue';
import { browserTracingIntegration } from '@sentry/vue';

import './bootstrap';
import '../css/app.css';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Portal';

createInertiaApp({
  title: (title) => title ? `${title} - ${appName}` : appName,
  progress: { color: '#4B5563' },
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup ({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    if (import.meta.env.VITE_SENTRY_ENABLED) {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_SENTRY_ENV,
        integrations: [
          browserTracingIntegration({
            tracePropagationTargets: ['localhost', 'https:portal.nightline.ac.uk'],
            routingInstrumentation: inertiaRoutingInstrumentation
          })
        ],

        tracesSampleRate: 0.01 // Capture 1% of the transactions for performance monitoring
      });
    }

    return app.use(plugin)
      .use(Portal)
      .component('Head', Head)
      .component('InertiaLink', Link)
      .component('Breadcrumb', Breadcrumb)
      .mixin({ methods: { route } })
      .mount(el);
  }
});

window.openImChannels = [];

const IM_PAGES = ['IM/Chats/Private', 'IM/Chats/Show', 'IM/Chats/Index'];
router.on('navigate', (event) => {
  if (!IM_PAGES.includes(event.detail.page.component)) {
    if (window.Echo) {
      window.openImChannels.forEach((channel) => {
        window.Echo.leave(channel);
      });
      window.openImChannels = [];
    }
    removeEcho();
  } else {
    addEcho();
  }
});

// Inertia routing instrumentation for Sentry performance monitoring
// Uses https://inertiajs.com/events
function inertiaRoutingInstrumentation (
  customStartTransaction,
  startTransactionOnPageLoad = true,
  startTransactionOnLocationChange = true
) {
  let activeTransaction;
  let name;
  if (startTransactionOnPageLoad) {
    name = route().current();
    activeTransaction = customStartTransaction({
      name,
      op: 'pageload',
      metadata: {
        source: 'route'
      }
    });
  }

  if (startTransactionOnLocationChange) {
    router.on('before', (_to, _from) => {
      if (activeTransaction) {
        activeTransaction.finish();
      }

      const newName = route().current();

      if (newName !== name) {
        activeTransaction = customStartTransaction({
          name: newName,
          op: 'navigation',
          metadata: {
            source: 'route'
          }
        });
      }
    });

    router.on('finish', () => {
      activeTransaction.setName(route().current(), 'route');
    });
  }
}
